import React from "react";
import { Tooltip } from "react-tooltip";

import "./App.scss";

// mobile
const isMobile =
  /mobile/i.test(navigator.userAgent) || window.screen.width < 1100;

function App() {
  const mediaList = [
    {
      icon: require("./assets/img/Header_Telegram.png"),
      url: "https://t.me/pieverse_news",
    },
    {
      icon: require("./assets/img/Header_X.png"),
      url: "https://x.com/pieverse_io",
    },
    // {
    //   icon: require("./assets/img/Header_Facebook.png"),
    //   url: "",
    // },
    // {
    //   icon: require("./assets/img/Header_Instagram.png"),
    //   url: "",
    // },
    // {
    //   icon: require("./assets/img/Medium.png"),
    //   url: "https://medium.com/@pieverse",
    // },
  ];

  return (
    <div className={isMobile ? "app app-mobile" : "app"}>
      <header className="app-header">
        <div className="ah-center">
          <div className="ah-link">
            <img
              src={require("./assets/img/Logo.png")}
              className="app-logo"
              alt="logo"
            />
            {!isMobile && (
              <div style={{ marginLeft: "32px" }}>
                <a href="#forPartners">For Partners</a>
                <a href="#forUser">For Users</a>
                <a href="#treasureHunt">Treasure Hunt</a>
              </div>
            )}
          </div>
          <div className="app-media">
            {mediaList.map((item, index) => (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.icon} alt="" />
              </a>
            ))}
          </div>
        </div>
      </header>
      <section className="app-overview flex-center">
        <img
          src={
            isMobile
              ? require("./assets/img/Home_overview_mobile.png")
              : require("./assets/img/Home_overview.png")
          }
          alt=""
        />
      </section>
      <section className="app-community flex-center">
        <img
          src={
            isMobile
              ? require("./assets/img/Home_community_mobile.png")
              : require("./assets/img/Home_community.png")
          }
          alt=""
        />
      </section>
      <section id="forPartners" className="app-for-partners flex-column-center">
        <img
          src={
            isMobile
              ? require("./assets/img/Home_redefineCommerce_mobile.png")
              : require("./assets/img/Home_redefineCommerce.png")
          }
          alt=""
        />
        <img
          src={require("./assets/img/Home_join_pieverse.png")}
          className="ar-join-btn"
          alt=""
          onClick={() => {
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSe0nGjKrQXxSdBVOyEfur87AFZrpYWW3ZdlDgVgOdjpFre-jA/viewform",
              "_blank"
            );
          }}
        />
      </section>
      <section className="app-brands flex-column-center">
        <img
          src={
            isMobile
              ? require("./assets/img/Home_brands_mobile.png")
              : require("./assets/img/Home_brands.png")
          }
          alt=""
        />
        <img
          src={require("./assets/img/Home_touch_btn.png")}
          className="ab-touch-btn"
          alt=""
          onClick={() => {
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSe0nGjKrQXxSdBVOyEfur87AFZrpYWW3ZdlDgVgOdjpFre-jA/viewform",
              "_blank"
            );
          }}
        />
      </section>
      <section id="forUser" className="app-for-user flex-center">
        <img
          src={
            isMobile
              ? require("./assets/img/Home_for_user_mobile.png")
              : require("./assets/img/Home_for_user.png")
          }
          alt=""
        />
        {!isMobile ? (
          <div className="afu-btns">
            <img
              // src={require("./assets/img/ForUser_download_btn.png")}
              src={require("./assets/img/Coming_soon_btn.png")}
              alt=""
            />
            <img
              // src={require("./assets/img/ForUser_access_btn.png")}
              src={require("./assets/img/Coming_soon_btn.png")}
              alt=""
            />
            <img
              // src={require("./assets/img/ForUser_earning_btn.png")}
              src={require("./assets/img/Coming_soon_btn.png")}
              alt=""
            />
          </div>
        ) : (
          <>
            <img
              // src={require("./assets/img/ForUser_download_btn.png")}
              src={require("./assets/img/Coming_soon_btn.png")}
              className="ForUser_download_btn"
              alt=""
            />
            <img
              // src={require("./assets/img/ForUser_access_btn.png")}
              src={require("./assets/img/Coming_soon_btn.png")}
              className="ForUser_access_btn"
              alt=""
            />
            <img
              // src={require("./assets/img/ForUser_earning_btn.png")}
              src={require("./assets/img/Coming_soon_btn.png")}
              className="ForUser_earning_btn"
              alt=""
            />
          </>
        )}
      </section>
      <section id="treasureHunt" className="app-treasure-hunt flex-center">
        <img
          src={
            isMobile
              ? require("./assets/img/Home_treasure_hunt_mobile.png")
              : require("./assets/img/Home_treasure_hunt.png")
          }
          alt=""
        />
        {!isMobile ? (
          <div className="ath-btns">
            <img
              src={require("./assets/img/TreasureHunt_hunt.png")}
              alt=""
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Coming Soon!"
              data-tooltip-place="top"
            />
            <img
              src={require("./assets/img/TreasureHunt_partner.png")}
              alt=""
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSe0nGjKrQXxSdBVOyEfur87AFZrpYWW3ZdlDgVgOdjpFre-jA/viewform",
                  "_blank"
                );
              }}
            />
          </div>
        ) : (
          <>
            <img
              src={require("./assets/img/TreasureHunt_hunt.png")}
              className="TreasureHunt_hunt"
              alt=""
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Coming Soon!"
              data-tooltip-place="top"
            />
            <img
              src={require("./assets/img/TreasureHunt_partner.png")}
              className="TreasureHunt_partner"
              alt=""
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSe0nGjKrQXxSdBVOyEfur87AFZrpYWW3ZdlDgVgOdjpFre-jA/viewform",
                  "_blank"
                );
              }}
            />
          </>
        )}
      </section>
      <section className="app-join flex-column-center">
        <img
          src={
            isMobile
              ? require("./assets/img/Home_join_mobile.png")
              : require("./assets/img/Home_join.png")
          }
          className="Home_join_mobile"
          alt=""
        />
        <img
          src={require("./assets/img/Home_join_pieverse.png")}
          className="aj-join-btn"
          style={{ opacity: 0, cursor: "default" }}
          alt=""
        />
        {!isMobile ? (
          <div className="aj-btns">
            <img
              src={require("./assets/img/Join_chat.png")}
              alt=""
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSe0nGjKrQXxSdBVOyEfur87AFZrpYWW3ZdlDgVgOdjpFre-jA/viewform",
                  "_blank"
                );
              }}
            />
            <img
              src={require("./assets/img/Join_explore.png")}
              alt=""
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Coming Soon!"
              data-tooltip-place="top"
            />
          </div>
        ) : (
          <>
            <img
              src={require("./assets/img/Join_chat.png")}
              className="Join_chat"
              alt=""
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSe0nGjKrQXxSdBVOyEfur87AFZrpYWW3ZdlDgVgOdjpFre-jA/viewform",
                  "_blank"
                );
              }}
            />
            <img
              src={require("./assets/img/Join_explore.png")}
              className="Join_explore"
              alt=""
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Coming Soon!"
              data-tooltip-place="top"
            />
          </>
        )}
      </section>
      <footer className="app-footer">
        <div
          className={`af-center flex-start justify-start ${
            isMobile ? "af-contact" : ""
          }`}
        >
          <div>
            <p className="contact-us_name">For General Enquiry</p>
            <a href="mailto:hello@pieverse.io" className="email-mailto">
              hello@pieverse.io
            </a>
          </div>
          <div className="afc-ml">
            <p className="contact-us_name">For Partnerships</p>
            <a href="mailto:partnership@pieverse.io" className="email-mailto">
              partnership@pieverse.io
            </a>
          </div>
        </div>
        <div className="af-center af-media">
          <div className="app-media">
            {mediaList.map((item, index) => (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.icon} alt="" />
              </a>
            ))}
          </div>
          <img
            src={require("./assets/img/Logo.png")}
            className="app-logo"
            alt="logo"
          />
        </div>
      </footer>
      <Tooltip id="my-tooltip" />
    </div>
  );
}

export default App;
